@font-face {
  font-family: Graphik;
  font-weight: 400;
  src: url(../../public/font/graphik400.woff) format("woff"),
    url(../../public/font/graphik400.woff2) format("woff2");
}

@font-face {
  font-family: Graphik;
  font-weight: 500;
  src: url(../../public/font/graphik500.woff) format("woff"),
    url(../../public/font/graphik500.woff2) format("woff2");
}

:root {
  --columns: 40;

  --vsq: calc(0.4dvw + 0.4dvh);
  --cell: calc((var(--vsq) * 50) / var(--columns));
  --pad-scale: 2;
  --pad: calc(var(--vsq) * var(--pad-scale));

  --easing: cubic-bezier(0.33, 1, 0.68, 1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: #ffffff;
  font-feature-settings: "ss01" on, "calt" off;
  line-height: 1.75;
  overflow-anchor: none;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  background-color: #111111;
  height: 100%;
}

body {
  background-color: #111111;
  color: #ffffff;
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #1c1c1c;
}

::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-player {
  aspect-ratio: 16/9;
  width: 100% !important;
  height: auto !important;
}

.react-player video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #000;
}

@keyframes wave {

  0%,
  100% {
    height: 4px;
    opacity: 0.3;
  }

  50% {
    height: 32px;
    opacity: 1;
  }
}

@keyframes wave-left-right {
  0% {
    transform: translateX(0);
    background-color: #000000;
    /* Start color */
  }

  50% {
    transform: translateX(5px);
    background-color: #FF0000;
    /* Change color to red */
  }

  100% {
    transform: translateX(0);
    background-color: #000000;
    /* Back to original color */
  }
}

::selection {
  background: #ffffff;
  color: #111111;
}

/* Update the root variables for better scaling */
:root {
  --columns: 40;
  --vsq: calc(0.4dvw + 0.4dvh);
  --cell: calc((var(--vsq) * 50) / var(--columns));
  --pad-scale: 2;
  --pad: calc(var(--vsq) * var(--pad-scale));
  --easing: cubic-bezier(0.33, 1, 0.68, 1);
}

/* Add styles for the table container */
.MuiTableContainer-root {
  max-width: 100%;
  overflow-x: auto;
}

/* Make table cells more compact */
.MuiTableCell-root {
  padding: 8px 12px !important;
  font-size: 0.875rem !important;
  white-space: nowrap;
}

/* Make buttons more compact */
.MuiButton-root {
  padding: 4px 12px !important;
  font-size: 0.875rem !important;
}

/* Make icons smaller */
.MuiIconButton-root {
  padding: 6px !important;
}

/* Adjust pagination size */
.MuiPagination-root {
  transform: scale(0.9);
  margin: 8px 0;
}

/* Make select inputs more compact */
.MuiSelect-select {
  padding: 8px 24px 8px 12px !important;
}

/* Adjust dialog size */
.MuiDialog-paper {
  margin: 16px;
  max-height: calc(100% - 32px);
}

/* Add horizontal scrolling for very small screens */
@media (max-width: 600px) {
  .MuiTable-root {
    min-width: 1000px;
  }
}

/* Fix Select component styling in dialogs */
.MuiDialog-paper .MuiSelect-select {
  background-color: transparent !important;
  color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  min-height: 1.4375em !important;
  padding: 16.5px 14px !important;
}

.MuiDialog-paper .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7);
  transform: translate(14px, 14px) scale(1);
}

.MuiDialog-paper .MuiInputLabel-root.Mui-focused,
.MuiDialog-paper .MuiInputLabel-root.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

/* Ensure consistent height for outlined inputs */
.MuiDialog-paper .MuiOutlinedInput-root {
  background-color: rgba(255, 255, 255, 0.05);
  min-height: 56px;
}

.MuiDialog-paper .MuiOutlinedInput-root {
  background-color: rgba(255, 255, 255, 0.05);
}

.MuiDialog-paper .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.23);
}

.MuiDialog-paper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.4);
}

.MuiDialog-paper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #90caf9;
}

.MuiDialog-paper .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.7);
}

.MuiDialog-paper .MuiInputLabel-root.Mui-focused {
  color: #90caf9;
}

/* Fix MenuItem styling in dropdowns */
.MuiMenu-paper {
  background-color: #1c1c1c !important;
  color: #ffffff !important;
}

.MuiMenuItem-root {
  color: #ffffff !important;
}

.MuiMenuItem-root:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
}